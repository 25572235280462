<template>
    <div>
        <!-- <h1 class="text-uppercase text-center text-primary">Prochainement</h1> -->
        <div class="mb-2 img-proche">
            <img class="img-fluid shadow-lg" src="@/assets/images/Prochainement.png" />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.img-proche
{
    padding:5% 10%;
}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
</style>